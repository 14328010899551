
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import EquipmentBrandAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentBrandAutocomplete.vue";

import { UtGelItem } from "@/domain";

import { IUtGelService } from "@/services";

@Component({
  components: {
    UpdateUi,
    EquipmentBrandAutocomplete,
  },
})
export default class UtGelUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: UtGelItem;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get utGelService(): IUtGelService {
    return this.container.resolve<IUtGelService>(S.UT_GEL_SERVICE);
  }

  loading: boolean = false;
  serialNumber: string | null = null;
  equipmentType: string | null = null;
  equipmentBrandId: string | null = null;

  mounted() {
    this.serialNumber = this.item.serialNumber;
    this.equipmentType = this.item.equipmentType;
    this.equipmentBrandId = this.item.equipmentBrand.id;
  }

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1 &&
      this.item.serialNumber.toUpperCase() != serialNumber.toUpperCase()
    );
  }

  async update() {
    try {
      this.loading = true;
      const updatedGel = await this.utGelService.update(this.item.id, {
        serialNumber: this.serialNumber!,
        equipmentType: this.equipmentType!,
        equipmentBrandId: this.equipmentBrandId!,
        disabled: this.item.disabled,
      });
      this.$emit("update", updatedGel);
    } finally {
      this.loading = false;
    }
  }
}
